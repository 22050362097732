@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');
// themes - our custom or/and out of the box themes
@import 'themes';
// framework component themes (styles tied to theme variables)
@import '@nebular/theme/styles/globals';
@import '@nebular/auth/styles/globals';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/grid';
// loading progress bar theme
@import './pace.theme';
@import './layout';
@import './overrides';

// install the framework and custom global styles
@include nb-install() {

  // framework global styles
  @include nb-theme-global();
  @include nb-auth-global();

  @include ngx-layout();
  // loading progress bar
  @include ngx-pace-theme();

  @include nb-overrides();

  // custom CSS
  .layout {
    overflow-y: hidden;
    position: relative;
  }

  // Global class
  .bold { font-weight: bold; }
  .underline { text-decoration: underline; }
  .italic { font-style: italic; }
  .clickable { cursor: pointer; }

  .text-link {
    cursor: pointer;

    &:hover {
      color: nb-theme(color-primary-400);
    }
  }

  .text-left { text-align: left; }
  .text-center { text-align: center; }
  .text-right { text-align: right; }

  // New element badge
  .badge {
    border-radius: 2rem;
    height: auto;
    width: auto;
    padding: .15rem .75rem;
    display: inline-flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    margin: 0;
    line-height: 20px;
    min-height: 20px;
    min-width: 20px;
    vertical-align: middle;
    text-align: center;
  }
  .badge.success {
    color: nb-theme(text-alternate-color);
    background: nb-theme(text-success-color);
  }
  .badge.infos {
    color: nb-theme(text-alternate-color);
    background: nb-theme(text-info-color);
  }
  .badge.warn {
    color: nb-theme(text-alternate-color);
    background: nb-theme(text-warning-color);
  }
  .badge.error {
    color: nb-theme(text-alternate-color);
    background: nb-theme(text-danger-color);
  }
  .badge.disabled {
    color: nb-theme(text-basic-color);
    background: nb-theme(text-disabled-color);
  }

  .colorInfos { color: nb-theme(text-info-color); }
  .colorSuccess { color: nb-theme(text-success-color); }
  .colorWarn { color: nb-theme(text-warning-color); }
  .colorError { color: nb-theme(color-danger-default); }
  .text-disabled { color: nb-theme(text-disabled-color); }

  .list-card {
    nb-card-header {
      border-bottom: none;
    }

    nb-card-body {
      padding: 0;
    }
  }

  .list-checkbox {
    position: absolute;
    left: 0px;
    margin-top: 0.4rem;
    width: 3rem;
    cursor: pointer;

    .label {
      cursor: pointer;
    }
  }

  .action-list nb-list-item {
    cursor: pointer;
  }

  .row {
    width: 100%;

    &.withoutMargin {
      margin-right: 0px;
      margin-left: 0px;
      padding-right: 0px;
      padding-left: 0px;

      >.col-12, .col-lg-12, .col-md-12 {
        padding-left: 0px;
        padding-right: 0px;
      }

      .firstCol {
        padding-left: 0px;
      }
      .lastCol {
        padding-right: 0px;
      }
    }
  }

  .moreActionIcon {
    vertical-align: middle;
    cursor: pointer;
  }

  .row-with-checkbox {
    padding-left: 33px;
  }

  .row-list-item-header {
    font-weight: bold;
  }

  .logoImageInList {
    max-height: 30px;
    max-width: 96%;
    left: 0px;
    right: 0px;
    margin: auto;
    text-align: center;
    position: relative;
    display: inline-block;
  }

  nb-list-item div {
    line-height: 30px;
  }

  .fieldLineContainer {

    .fieldTitle {
      font-weight: bold;
      font-size: 1rem;
    }

    .fieldSubTitle {
      font-style: italic;
      font-size: 0.8rem;
      color: nb-theme(color-basic-600);
    }

    .fieldValue {
      margin-top: 0.5rem;

      .toogleWithInput {
        display: inline-block;
        position: relative;
        top: 0.6rem;
        margin-right: 1rem;
      }
    }
  }

  .iconCopyData, .iconEditData {
    color: nb-theme(color-basic-600);
    font-size: 0.9375rem;
    display: inline-block;
    top: 0.06rem;
    position: relative;
    cursor: pointer;
    margin-left: 0.3rem;
  }

  .iconEditData {
    color: nb-theme(text-info-color);
  }

  .iconError {
    color: nb-theme(color-danger-default);
    display: inline-block;
    font-size: 20px;
    line-height: 30px;
    height: 30px;
    top: 3px;
    position: absolute;
  }

  .list-card {
    nb-list-item.headerList {
      background-color: nb-theme(color-basic-200);
    }
  }

  nb-select {
    ::ng-deep {
      .select-button {
        padding-right: 25px;
        padding-left: 13px;
      }
    }
  }

  nb-select.select-multi{
    ::ng-deep {
      .select-button {
        white-space: initial;
      }
    }
  }

  ::ng-deep {
    nb-toast .fullText {
      .message {
        width: 100%;
      }
    }
  }

  ::ng-deep {
    nb-action {
      cursor: pointer;
    }
  }

}

::ng-deep {
  .cdk-overlay-container {
    z-index: 1070 !important;
  }
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.booked { color: var(--ticketColorBooked) }
.toConfirm { color: var(--ticketColorToConfirm) }
.alerted { color: var(--ticketColorAlerted) }
.called { color: var(--ticketColorCalled) }
.onHold { color: var(--ticketColorOnHold) }
.noShow { color: var(--ticketColorNoShow) }
.inProgress { color: var(--ticketColorInProgress)}
.cancelled { color: var(--ticketColorCancelled) }
.done { color: var(--ticketColorDone) }


:root {
  --ticketColorBooked : #38B6FF;
  --ticketColorToConfirm : #5271FF;
  --ticketColorAlerted : #7ED957;
  --ticketColorCalled : #008037;
  --ticketColorOnHold : #FFDE59;
  --ticketColorNoShow : #FFBD59;
  --ticketColorInProgress : #623CEA;
  --ticketColorCancelled : #FD2D00;
  --ticketColorDone : #000000;
}